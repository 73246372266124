//adminroutes
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from '../wrapper/ProtectedRoute'; // adjust the import path as needed
import AdminDashboard from 'src/screens/admin/Dashboard';
import ConfigureLLM from 'src/screens/admin/ConfigureLLM';
import ChatSettings from 'src/screens/admin/ChatSettings';
import ManageKnowledgeBase from 'src/screens/admin/ManageKnowledgeBase';
import OtherSettings from 'src/screens/admin/OtherSettings';
import Dashboard from 'src/screens/user/Dashboard';
import { useAuth } from 'src/hooks/useAuth';
import { UserRole } from 'src/enums/User';
import { userRoutes } from './user';
import { authRoutes } from './auth';
import { NotificationsProvider } from 'src/hooks/useNotifications';


const baseRoute = "/admin"

export const adminRoutes = {
    DASHBOARD: `dashboard`,
    CONFIGURE_LLM:  `configureLLM`,
    OTHER_SETTINGS:  `otherSettings`,
    CHAT_SETTINGS: `chatSettings`,
    MANAGE_KNOWLEDGEBASE: `manageKnowledgebase`,
}



const AdminRoutes = () => {

  return (
    <Routes>
      {/* <Route path={"/"} element={<AdminDashboard />} /> */}
      {/* <Route index element={<AdminDashboard />} /> */}
      <Route path={adminRoutes.DASHBOARD} element={<AdminDashboard />} />
      <Route path={adminRoutes.CONFIGURE_LLM} element={<ConfigureLLM />} />
      <Route path={adminRoutes.CHAT_SETTINGS} element={<ChatSettings />} />
      <Route path={adminRoutes.MANAGE_KNOWLEDGEBASE} element={<ManageKnowledgeBase />} />
      <Route path={adminRoutes.OTHER_SETTINGS} element={<OtherSettings />} />
      {/* Redirect to the admin dashboard if an unknown admin route is accessed */}
      <Route path="/" element={<Navigate replace to={adminRoutes.DASHBOARD} />} />
    </Routes>
  );
};

export default AdminRoutes;
