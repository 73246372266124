//chatmessageinput
import { PauseCircleIcon } from '@heroicons/react/24/solid';
import { Textarea, ActionIcon, Box, createStyles, useMantineTheme, Styles, TextInputStylesNames, InputStylesParams } from '@mantine/core';
import { ArrowForward, Margin, Send, SendRounded } from '@mui/icons-material';
import { useEffect, useRef } from 'react';
import { COLORS } from 'src/utils/styles';
import Icon from '../Icons/Icons/Icon';

interface ChatInputProps{
inputMessage: string,
setInputMessage: React.Dispatch<React.SetStateAction<string>>
onSend: () => void
onStopStream: () => void
disabled?: boolean
minRows?: number
streaming?: boolean
maxRows?: number
containerClassname?: string
}

const useStyles = createStyles((theme) => ({
  textArea: {
    marginBottom: '0.25rem',
  },
}));

const ChatInput = (props: ChatInputProps) => {
  const {inputMessage, setInputMessage, onSend, disabled, minRows, maxRows, streaming, onStopStream} = props
  const { classes } = useStyles();
  const theme = useMantineTheme()
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
      const textarea = textareaRef.current;
      if (textarea) {
          textarea.scrollTop = textarea.scrollHeight;
      }
  }, [inputMessage]);

  return (
    <div className={classes.textArea}>
    <Textarea
      autosize
      size="sm"
      minRows={minRows || 1}
      maxRows={maxRows || 4}
      value={inputMessage}
      onChange={(e) => setInputMessage(e.currentTarget.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && e.shiftKey) {
          e.preventDefault();
        }
      }}
      styles={(theme) => ({
        input: {
          '&:focus': {
            borderColor: COLORS.primary,
          },
          paddingRight: '3rem',
          '&::placeholder': {
            color: streaming ? '#aa2c76' : undefined,
            animation: streaming ? 'pulse 2s ease-in-out infinite' : undefined
          },
          '@keyframes pulse': {
            '0%': { color: '#aa2c76' },
            '50%': { color: theme.colors.gray[5] },
            '100%': { color: '#aa2c76' }
          }
        },
        rightSection: {
          marginRight: 10
        }
      })}
      radius={10}
      placeholder={streaming ? "Caira is typing :)" : "Ask Caira"}
      rightSection={
        streaming ? <ActionIcon variant="outline" radius={5} style={{color: "black"}} onClick={onStopStream}>
        <Icon name="stop" size='1.3rem' color={theme.colorScheme == "dark" ? "white" : 'black'}/>
    </ActionIcon>  : <ActionIcon bg={COLORS.primary} disabled={disabled} onClick={onSend}>
            <ArrowForward style={{color: "white"}}  />
        </ActionIcon> 
      }
    />
    </div>
)};

export default ChatInput;
