import { TrashIcon } from "@heroicons/react/24/solid";
import {
  Drawer,
  Progress,
  Text,
  Stack,
  useMantineTheme,
  Group,
  Loader,
  CheckIcon,
  Divider,
  Button,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Cancel, CancelRounded } from "@mui/icons-material";
import { CircularProgress, colors } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { PubSubNotificationTypes } from "src/enums/Notifications";
import { useNotifications } from "src/hooks/useNotifications";
import { Documents } from "src/interfaces/Documents";
import { COLORS } from "src/utils/styles";

interface UploadFilesDrawer {
  files: Documents[];
  visible?: boolean;
  onPressClose?: () => void;
}

interface FileIcon {
  status: string | undefined;
}

const FileIcon = (props: FileIcon) => {
  const { status } = props;
  return (
    <div className="flex-1 max-w-[10%] overflow-hidden flex items-center justify-center">
      {status === "Completed" ? (
        <div
          className="bg-green-500 flex items-center justify-center rounded-full"
          style={{ width: "15px", height: "15px" }}
        >
          <CheckIcon className="w-2 h-2 text-white fill-current" />
        </div>
      ) : status === "Deleted" ? (
        <div
          className=" bg-red-600 flex items-center justify-center rounded-full"
          style={{ width: "15px", height: "15px" }}
        >
          <TrashIcon className="w-2 h-2 text-white fill-current" />
        </div>
      ) : status === "Upload Failed" || status === "File content exceeds 500K characters" ? (
        <div
          className=" flex items-center justify-center rounded-full"
          style={{ width: "15px", height: "15px" }}
        >
          <CancelRounded className="w-4 h-4 text-red-600 fill-current" />
        </div>
      ) : (
        <Loader size={15} />
      )}
    </div>
  );
};

function UploadFilesDrawer(props: UploadFilesDrawer) {
  const { files, onPressClose } = props;
  const [opened, { open, close }] = useDisclosure(false);
  const client = useNotifications()?.client;
  const isMatchces = useMediaQuery("(max-width: 992px)");
  const [list, setList] = useState(files);
  const [initialized, setInitialized] = useState(false);
  const [processedCount, setProcessedCount] = useState(0);
  const useTheme = useMantineTheme();

  const closeList = useCallback(() => {
    close();
  }, [close]);

  const wipeList = useCallback(() => {
    close();
    setList((prev) => []);
    setProcessedCount(0);
  }, []);

  const normalizeFilename = useCallback((filename: string) => {
    // Remove special characters and spaces, convert to lowercase
    return filename.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  }, []);

  const listenForQueueFiles = useCallback(() => {
    if (!initialized) {
      if (!client) {
        console.warn("WebSocket client not initialized");
        return;
      }

      client.on("server-message", (data) => {
        if (data.message.data) {
          console.log("Notification received", data.message.data);
          const message = JSON.parse(data.message.data.toString());
          if (message.event_type === PubSubNotificationTypes.fileUpload) {
            setList((prevFiles) => {
              const newFiles = prevFiles.map((file) => {
                // Use normalized filenames for comparison
                if (normalizeFilename(file.filename) === normalizeFilename(message.file_name)) {
                  return { ...file, status: message.status };
                }
                return file;
              });

              if (message.status == "Completed" || message.status == "Upload Failed" || message.status == "File content exceeds 500K characters") {
                setProcessedCount((prev) => prev + 1);
              }

              const allCompleted = newFiles.every(
                (file) =>
                  file.status === "Completed" || file.status === "Upload Failed" || file.status == "File content exceeds 500K characters"
              );

              if (allCompleted) {
                if (onPressClose) {
                  onPressClose();
                  setTimeout(wipeList, 2000);
                }
              }

              return newFiles;
            });
          }
          if (message.event_type === PubSubNotificationTypes.fileDeletion) {
            setList((prevFiles) => {
              const newFiles = prevFiles.map((file) => {
                // Use normalized filenames for comparison
                if (normalizeFilename(file.filename) === normalizeFilename(message.file_name)) {
                  return { ...file, status: message.status };
                }
                return file;
              });

              if (message.status == "Deleted") {
                setProcessedCount((prev) => prev + 1);
              }

              const allCompleted = newFiles.every((file) => file.status === "Deleted");

              if (allCompleted) {
                if (onPressClose) {
                  console.log("Here is the call");
                  onPressClose();
                  setTimeout(wipeList, 2000);
                }
              }

              return newFiles;
            });
          }
        }
      });

      setInitialized(true);
    }
  }, [initialized, client, onPressClose, wipeList, normalizeFilename]);

  useEffect(() => {
    if (files.length > 0) {
      setList(files);
    }
  }, [files]);

  useEffect(() => {
    if (list?.length > 0) {
      open();
      listenForQueueFiles();
    }
  }, [list, open, listenForQueueFiles]);

  const getStatusColor = useCallback((status: string | undefined) => {
    switch (status) {
      case "Uploading":
        return colors.blue[600];
      case "Uploaded":
        return colors.blue[600];
      case "Chunking and Vectorising":
        return colors.blue[600];
      case "Completed":
        return colors.green[400];
      case "Queued For Google Drive Transfer":
        return colors.blue[600];
      case "Transferring From Google Drive":
        return colors.blue[600];
      case "Deleting":
        return colors.red[600];
      case "File content exceeds 500K characters":
        return colors.red[600];
      case "Deleted":
        return colors.red[600];
      default:
        return colors.blue[600];
    }
  }, []);

  const getFileStatus = (status: string | undefined) => {
    if (status == "Chunking and Vectorising") {
      return "Processing";
    } else {
      return status;
    }
  };

  return (
    <>
      <Drawer
        opened={opened}
        transitionProps={{
          transition: "rotate-left",
          duration: 150,
          timingFunction: "linear",
        }}
        withCloseButton={false}
        onClose={closeList}
        title={
          <Group position="apart">
            <Text>{list?.length > 1 ? "Kindly wait up to 30sec" : "Kindly wait up to 30sec"}</Text>
            <Button size="xs" variant="outline" color="blue" style={{ backgroundColor: "white" }} onClick={wipeList}>
              Cancel
            </Button>
          </Group>
        }
        padding="lg"
        position="bottom"
        withOverlay={false}
        styles={(theme) => ({
          inner: {
            maxWidth: isMatchces ? "100%" : 350,
          },
          body: {
            marginTop: 10,
          },
          title: {
            fontStyle: "italic",
            color: theme.colorScheme == "dark" ? "white" : "black",
            fontWeight: 500,
          },
          header: {
            backgroundColor: theme.colorScheme == "dark" ? theme.colors.dark[8] : theme.colors.blue[1],
            borderRadius: "16px 16px 0 0",
            position: "sticky",
            height: 10,
          },
        })}
      >
        <Text style={{ fontSize: 14, color: colors.blueGrey[700] }}>
          {processedCount} of {list?.length} {list?.length > 1 ? "documents" : "document"} processed
        </Text>
        {list.map((file, index) => (
          <>
            <div key={index} className="flex w-full items-center py-2">
              <div className="flex-1 max-w-[90%] overflow-hidden">
                <Text style={{ fontSize: 14 }}>{file.filename.replace(/[\s_]/g, '')}</Text>
                <Text style={{ paddingTop: 5, fontSize: 11, color: getStatusColor(file.status), fontWeight: "bold" }}>
                  {getFileStatus(file.status)}
                </Text>
              </div>
              <FileIcon status={file?.status} />
            </div>
            <Divider w={"100%"} />
          </>
        ))}
      </Drawer>
    </>
  );
}

export default UploadFilesDrawer;
